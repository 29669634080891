//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { uploadFile, getFileUrl } from "@/lib/pinata-proxy";
import { createService, createServiceFee, claimRequestService } from "@/lib/polkadotProvider/command/services";
import { queryLabsById } from "@/lib/polkadotProvider/query/labs";
import { getProvideRequestService, getCategories, getConversionCache, getDNACollectionProcess } from "@/lib/api";
import { toEther, formatUSDTE } from "@/lib/balance-format";
import { generalDebounce } from "@/utils";
import DialogErrorBalance from "@/components/Dialog/DialogErrorBalance";
const englishAlphabet = val => val && /^[A-Za-z0-9!@#$%^&*\\(\\)\-_=+:;"',.\\/? ]+$/.test(val) || "This field can only contain English alphabet";
export default {
  name: "AddLabServices",
  components: {
    DialogErrorBalance
  },
  data: () => ({
    document: {
      category: "",
      dnaCollectionProcess: "",
      name: "",
      currency: "USDT.e",
      price: 0,
      qcPrice: 0,
      description: "",
      longDescription: "",
      duration: "",
      durationType: "Days",
      linkKit: null
    },
    kitPurchaseLink: null,
    imageUrl: "",
    testResultSampleUrl: "",
    usdRate: 0,
    statusLab: null,
    labInfo: null,
    messageWarning: {},
    serviceFlow: "RequestTest",
    files: [],
    testResultSampleFile: [],
    listCategories: [],
    isLoading: false,
    showModalAlert: false,
    currencyList: ["USDT.e"],
    listExpectedDuration: ["Hours", "Days"],
    dnaCollectionProcessList: [],
    isBiomedical: false,
    isShowError: false,
    fee: 0
  }),
  computed: {
    ...mapState({
      servicePayload: state => state.lab.providePayload,
      api: state => state.substrate.api,
      exist: state => state.substrate.isLabAccountExist,
      wallet: state => state.substrate.wallet,
      web3: state => state.metamask.web3,
      lastEventData: state => state.substrate.lastEventData
    }),
    priceHint() {
      return "".concat(this.document.price, " ").concat(this.document.currency, " = ").concat((this.usdRate.conversion * this.document.price).toFixed(4), " USD");
    },
    qcPriceHint() {
      return "".concat(this.document.qcPrice, " ").concat(this.document.currency, " = ").concat((this.usdRate.conversion * this.document.qcPrice).toFixed(4), " USD");
    },
    hasServicePayload() {
      return Boolean(Object.keys(this.servicePayload).length);
    },
    fieldRequiredRule() {
      return [val => !!val || "This field is required"];
    },
    linkRules() {
      return [val => /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,7}\b([-a-zA-Z0-9()@:%_.~#?&/=]*)/.test(val) || "Link is invalid"];
    },
    serviceNameRules() {
      return [val => val && val.length <= 50 || "This field only allows 50 characters."];
    },
    decimalRule() {
      return [val => /^\d*(\.\d{0,4})?$/.test(val) || this.isBiomedical || "This field only allows 4 decimal characters."];
    },
    fieldEnglishRules() {
      return [englishAlphabet];
    },
    descriptionRules() {
      return [val => val && val.length <= 100 || "This field only allows 100 characters."];
    },
    fileInputRules() {
      return [value => !Array.isArray(value) || "This field is required", value => !Array.isArray(value) && (value === null || value === void 0 ? void 0 : value.size) < 2000000 || "The total file size uploaded exceeds the maximum file size allowed (2MB)", value => !Array.isArray(value) && (value === null || value === void 0 ? void 0 : value.type) === "application/pdf" || "The files uploaded are not in the supported file formats."];
    }
  },
  async mounted() {
    this.dnaCollectionProcessList = (await getDNACollectionProcess()).data;
  },
  async created() {
    this.validate();
    this.prefillValues();
    await this.getServiceCategory();
    this.usdRate = await getConversionCache(this.document.currency === "USDT.e" ? "USDT" : this.document.currency, "USD");
  },
  methods: {
    async validate() {
      var _this$labInfo;
      this.labInfo = await queryLabsById(this.api, this.wallet.address);
      const gitbookLink = "<a href=\"https://t.me/debionetwork\" target=\"_blank\">contact us</a>";
      const MESSAGE = Object.freeze({
        UNVERIFIED: {
          type: "UNVERIFIED",
          actionTitle: "Close",
          title: "Your verification process is still under review",
          subtitle: "\n            We're sorry to say that you cannot provide a service until you are verified.\n            Please ".concat(gitbookLink, " for more infomation\n          ")
        },
        REJECTED: {
          type: "REJECTED",
          actionTitle: "Close",
          title: "Your verification process is rejected",
          subtitle: "\n            We're sorry to say that you cannot provide a service because your verification status is rejected\n            Please contact us ".concat(gitbookLink, " for more infomation\n          ")
        },
        REVOKED: {
          type: "REVOKED",
          actionTitle: "Close",
          title: "Your verification process is revoked",
          subtitle: "\n            We're sorry to say that you cannot provide a service because your verification status is revoked\n            Please contact us ".concat(gitbookLink, " for more infomation\n          ")
        },
        NOT_EXIST: {
          type: "NOT_EXIST",
          actionTitle: "Proceed",
          title: "You are not registered yet",
          subtitle: "Complete your registration process first before continue"
        },
        CITY_NOT_MATCH: {
          type: "CITY_NOT_MATCH",
          actionTitle: "Close",
          title: "Add service failed",
          subtitle: "Your location is not match with the requested service!"
        }
      });
      if (!this.labInfo) {
        this.showModalAlert = true;
        this.messageWarning = MESSAGE["NOT_EXIST"];
      }
      if (((_this$labInfo = this.labInfo) === null || _this$labInfo === void 0 ? void 0 : _this$labInfo.verificationStatus) === "Verified" && Object.keys(this.servicePayload).length) {
        var _this$labInfo$info, _this$labInfo$info2;
        if (((_this$labInfo$info = this.labInfo.info) === null || _this$labInfo$info === void 0 ? void 0 : _this$labInfo$info.country) !== this.servicePayload.countryCode || ((_this$labInfo$info2 = this.labInfo.info) === null || _this$labInfo$info2 === void 0 ? void 0 : _this$labInfo$info2.region) !== this.servicePayload.cityCode) {
          this.showModalAlert = true;
          this.messageWarning = MESSAGE["CITY_NOT_MATCH"];
          await this.$store.dispatch("lab/setProvideService", {});
          return;
        }
      }
      this.statusLab = this.labInfo.verificationStatus;
      if (this.statusLab === "Verified") return;
      const notExist = !this.exist || !this.labInfo.certifications.length || !this.labInfo.services.length;
      const compute = notExist ? "NOT_EXIST" : this.labInfo.verificationStatus.toUpperCase();
      this.messageWarning = MESSAGE[compute];
      this.showModalAlert = true;
    },
    async getServiceCategory() {
      const {
        data: data
      } = await getCategories();
      this.listCategories = data;
    },
    prefillValues() {
      const checkQuery = Object.keys(this.servicePayload).length;
      if (!checkQuery) return;
      const {
        category,
        serviceFlow
      } = this.servicePayload;
      this.document.category = category;
      this.document.serviceFlow = serviceFlow;
      this.serviceFlow = serviceFlow;
    },
    async getCreateServiceFee() {
      const {
        category,
        dnaCollectionProcess,
        name,
        currency,
        price,
        qcPrice,
        description,
        longDescription,
        duration,
        durationType
      } = this.document;
      const newService = {
        name,
        pricesByCurrency: [{
          currency: formatUSDTE(currency),
          totalPrice: await toEther(price + qcPrice, currency),
          priceComponents: [{
            component: "testing_price",
            value: await toEther(price, currency)
          }],
          additionalPrices: [{
            component: "qc_price",
            value: await toEther(qcPrice, currency)
          }]
        }],
        expectedDuration: {
          duration,
          durationType
        },
        category,
        description,
        longDescription,
        dnaCollectionProcess,
        image: this.imageUrl,
        testResultSample: this.testResultSampleUrl
      };
      const fee = await createServiceFee(this.api, this.wallet, newService, this.serviceFlow);
      const checkQuery = Object.keys(this.servicePayload).length;
      const txWeight = this.web3.utils.fromWei(String(fee.partialFee), "ether");
      const claimRequestServiceFee = 0.0203; // Static Estimated claimRequestServiceFee

      if (!checkQuery) {
        this.fee = "".concat(Number(txWeight).toFixed(4), " DBIO");
        return;
      }
      this.fee = "".concat((Number(txWeight) + claimRequestServiceFee).toFixed(4), " DBIO");
    },
    async handleCreateService() {
      if (this.isLoading) return; // If function already running return.
      if (!this.$refs.addServiceForm.validate()) {
        return;
      }
      this.isLoading = true;
      try {
        const {
          category,
          dnaCollectionProcess,
          name,
          currency,
          price,
          qcPrice,
          description,
          longDescription,
          duration,
          durationType
        } = this.document;
        const hexLongDescription = this.web3.utils.utf8ToHex(longDescription);
        const hexDescription = this.web3.utils.utf8ToHex(description);
        const newService = {
          name,
          pricesByCurrency: [{
            currency: formatUSDTE(currency),
            totalPrice: await toEther(price + qcPrice, currency),
            priceComponents: [{
              component: "testing_price",
              value: await toEther(price, currency)
            }],
            additionalPrices: [{
              component: "qc_price",
              value: await toEther(qcPrice, currency)
            }]
          }],
          expectedDuration: {
            duration,
            durationType
          },
          category,
          description: hexDescription,
          longDescription: "".concat(hexLongDescription).concat(this.document.linkKit === "yes" && "||".concat(this.kitPurchaseLink)),
          dnaCollectionProcess,
          image: this.imageUrl,
          testResultSample: this.testResultSampleUrl
        };
        await createService(this.api, this.wallet, newService, this.serviceFlow);
      } catch (err) {
        this.isLoading = false;
        if (err.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isShowError = true;
        }
        console.error(err);
      }
    },
    closeDialog() {
      this.isShowError = false;
    },
    async imageUploadEventListener(file) {
      this.isLoading = true;
      this.imageUrl = "";
      if (file) {
        if (file.name.lastIndexOf(".") <= 0) {
          return;
        }
        const dataFile = await this.setupFileReader(file);
        const result = await uploadFile({
          title: dataFile.name,
          type: dataFile.type,
          size: dataFile.size,
          file: dataFile
        });
        const link = getFileUrl(result.IpfsHash);
        this.imageUrl = link;
        this.isLoading = false;
      }
    },
    setupFileReader(value) {
      return new Promise((resolve, reject) => {
        const file = value;
        const fr = new FileReader();
        fr.onload = async function () {
          resolve(value);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file);
      });
    },
    async fileUploadEventListener(file) {
      if (!file || file.size >= 2000000) {
        return;
      }
      this.isLoading = true;
      this.testResultSampleUrl = "";
      if (file) {
        if (file.name.lastIndexOf(".") <= 0) {
          return;
        }
        const dataFile = await this.setupFileReader(file);
        const result = await uploadFile({
          title: dataFile.name,
          type: dataFile.type,
          size: dataFile.size,
          file: dataFile
        });
        const link = getFileUrl(result.IpfsHash);
        this.testResultSampleUrl = link;
        this.isLoading = false;
      }
    },
    async handleClaimRequest(id) {
      try {
        if (Object.keys(this.servicePayload).length) {
          const dataRequestServices = await getProvideRequestService(this.servicePayload);
          this.isLoading = true;
          for (let i = 0; i < dataRequestServices.length; i++) {
            await claimRequestService(this.api, this.wallet, {
              id,
              hash: dataRequestServices[i].request.hash
            });
          }
          this.isLoading = false;
          this.$router.push("/lab/services");
          this.$store.dispatch("lab/setProvideService", {});
        }
      } catch (err) {
        if (err.message === "1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low") {
          this.isShowError = true;
        }
        console.error(err);
        this.isLoading = false;
      }
    },
    handleRedirect() {
      var _this$messageWarning;
      const REDIRECT_TO = Object.freeze({
        UNVERIFIED: {
          name: "lab-dashboard"
        },
        REJECTED: {
          name: "lab-dashboard"
        },
        REVOKED: {
          name: "lab-dashboard"
        },
        NOT_EXIST: {
          name: "lab-registration"
        },
        CITY_NOT_MATCH: {
          name: "lab-dashboard-services"
        }
      });
      if (((_this$messageWarning = this.messageWarning) === null || _this$messageWarning === void 0 ? void 0 : _this$messageWarning.type) === "CITY_NOT_MATCH") {
        this.$router.push(REDIRECT_TO["CITY_NOT_MATCH"]);
        return;
      }
      const notExist = !this.exist || !this.labInfo.certifications.length || !this.labInfo.services.length;
      const compute = notExist ? "NOT_EXIST" : this.statusLab.toUpperCase();
      this.$router.push(REDIRECT_TO[compute]);
    },
    selectPicture() {
      this.$refs.fileInput.$refs.input.click();
    }
  },
  watch: {
    category() {
      if (this.document.category == "Covid-19") {
        this.isBiomedical = true;
        this.document.qcPrice = "0";
      } else {
        this.isBiomedical = false;
      }
    },
    lastEventData(val) {
      if (val === null) return;
      const dataEvent = JSON.parse(val.data.toString());
      if (val.method === "ServiceCreated") {
        if (dataEvent[1] === this.wallet.address && Object.keys(this.servicePayload).length) this.handleClaimRequest(dataEvent[0].id);else this.$router.push("/lab/services");
      }
    },
    document: {
      deep: true,
      immediate: true,
      handler: generalDebounce(async function () {
        await this.getCreateServiceFee();
      }, 500)
    }
  }
};